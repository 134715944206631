import React from 'react'
import { node, bool, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Surround.module.scss'

const Surround = ({
  children,
  shadow,
  solid,
  rounded,
  className,
  isDark,
  wide,
  padded,
  ...other
}) => {
  return (
    <div
      className={classNames(
        styles.Surround,
        isDark && styles.inverse,
        rounded && styles.rounded,
        shadow && styles.shadow,
        solid && styles.solid,
        wide && styles.wide,
        padded && styles.padded,
        className
      )}
      {...other}
    >
      {children}
    </div>
  )
}

Surround.propTypes = {
  children: node.isRequired,
  rounded: bool,
  shadow: bool,
  solid: bool,
  isDark: bool,
  wide: bool,
  padded: bool,
  className: string
}

export default Surround
