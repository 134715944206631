export default {
  bbfc: [
    [
      {
        text: 'Media Centre',
        url: '/about-us/news'
      },
      {
        text: 'Contact us',
        url: '/about-us/contact-us'
      }
    ],
    [
      {
        text: 'About us',
        url: '/about-us'
      },
      {
        text: 'About Classification',
        url: '/about-classification'
      },
      {
        text: 'Education',
        url: '/education'
      },
      {
        text: 'Case Studies',
        url: '/education/case-studies'
      },
      {
        text: 'Industry',
        url: '/industry-services'
      }
    ],
    [
      {
        text: 'Website Terms and Conditions',
        url: '/terms-and-conditions'
      },
      {
        text: 'Privacy Statement',
        url: '/privacy-statement'
      }
    ]
  ],
  cbbfc: [
    [
      {
        text: 'Contact Us',
        url: '/contact-us'
      },
      {
        text: 'What is the BBFC?',
        url: '/what-is-the-bbfc'
      }
    ],
    [
      {
        text: 'Resources',
        url: '/resources'
      },
      {
        text: 'What we do',
        url: '/what-we-do'
      }
    ],
    [
      {
        text: 'Film Ratings',
        url: '/film-ratings'
      },
      {
        text: 'Privacy Statement',
        url: '/privacy-statement'
      }
    ]
  ]
}
