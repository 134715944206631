import React from 'react'
import { node, object, string } from 'prop-types'
import A11yNavigation from '@/component/Primitive/A11yNavigation'
import Footer from '@/component/Common/Footer'
import Header from '@/component/Common/Header'
import Main from '@/component/Primitive/Main'
import SiteContainer from '@/component/Primitive/SiteContainer'
import ResponsiveSpacer from '@/component/Primitive/ResponsiveSpacer'
import ScrollTopButton from '@/component/Common/ScrollTopButton'

const MainLayout = ({ children, user, instance, searchTerm }) => {
  return (
    <SiteContainer>
      <A11yNavigation>
        <a href="#content">Jump to main content</a>
        <a href="#navigation">Jump to primary navigation</a>
      </A11yNavigation>
      <Header user={user} instance={instance} searchTerm={searchTerm} />
      <Main>{children}</Main>
      <ResponsiveSpacer />
      <ScrollTopButton />
      <Footer />
    </SiteContainer>
  )
}

MainLayout.propTypes = {
  children: node.isRequired,
  user: object,
  instance: object,
  searchTerm: string
}

export default MainLayout
