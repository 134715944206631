import React from 'react'
import { object } from 'prop-types'
import { useThemeContext } from '@/component/Context/ThemeContext'

import Container from '@/component/Primitive/Container'
import SmartLink from '@/component/Primitive/SmartLink'
import List from '@/component/Primitive/List'
import Icon from '@/component/Primitive/Icon'
import SocialLink from './component/SocialLink'

import links from './links.js'

const Footer = ({ styles }) => {
  const theme = useThemeContext()
  return (
    <footer className={styles.Footer}>
      <Container gutter center size="huge" className={styles.Container}>
        <div className={styles.Branding}>
          <SmartLink as="/" to="/section">
            <Icon type={`${theme}-logo--white`} a11yText={`${theme} logo`} />
          </SmartLink>
          <div className={styles.Social}>
            <SocialLink
              type="twitter"
              text="Follow us"
              url="https://twitter.com/BBFC"
            />
            <SocialLink
              type="facebook-round"
              text="Find us"
              url="https://www.facebook.com/BritishBoardFilmClassification"
            />
            <SocialLink
              type="instagram"
              text="Follow us"
              url="https://www.instagram.com/bbfc_ageratings"
            />
          </div>
        </div>
        <div className={styles.LinkListWrapper}>
          {links[theme].map((linkList, i) => (
            <List key={i} className={styles.LinkList}>
              {linkList.map((link, j) => (
                <SmartLink key={j} as={link.url} to="/section">
                  {link.text}
                </SmartLink>
              ))}
            </List>
          ))}
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  styles: object
}

export default Footer
