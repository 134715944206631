import React, { useEffect, useState } from 'react'

import IconButton from '@/component/Primitive/IconButton'
import styles from './ScrollTopButton.module.scss'
import classNames from 'classnames'

const ScrollTopButton = () => {
  const [showButton, setShowButton] = useState(false)

  const onScroll = () => {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop

    const scrollTopHeight = window.innerHeight * 4
    setShowButton(scrollPosition >= scrollTopHeight)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div
      className={classNames(
        styles.ScrollTopButton,
        !showButton && styles.Hidden
      )}
    >
      <IconButton
        type="button"
        rounded
        solid
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        icon="chevron"
      />
    </div>
  )
}

export default ScrollTopButton
