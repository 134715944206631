const createQuery = () => `
query User($url: String!) {
  instance(url: $url) {
    theme
    _id
    googleTagManagerId
    name
  }
  user {
    _id
    name
    email
  }
}`

export default createQuery
