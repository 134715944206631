import React from 'react'
import { string, func } from 'prop-types'
import classNames from 'classnames'
import { RatingContextProvider } from '@/component/Context/RatingContext'

import AgeRating from '@/component/Primitive/AgeRating'
import RatingsDetail from './RatingsDetail'
import RatingColor from '@/component/Common/RatingColor'

import { displayRatings } from '../../../../../../data/ratings.json'
import styles from './RatingsList.module.scss'

const RatingsList = ({ activeItem, handleActiveToggle }) => {
  return (
    <div className={styles.RatingsList}>
      {displayRatings.map((rating) => (
        <RatingContextProvider key={rating} rating={rating}>
          <button
            className={classNames(
              styles.Rating,
              activeItem === rating && styles.isActive
            )}
            onClick={() => handleActiveToggle(rating)}
          >
            <RatingColor>
              <AgeRating className={styles.RatingIcon} />
            </RatingColor>

            {activeItem === rating && (
              <div className={styles.DetailsWrapper}>
                <RatingsDetail
                  rating={rating}
                  closeHandler={() => handleActiveToggle('')}
                />
              </div>
            )}
          </button>
        </RatingContextProvider>
      ))}
    </div>
  )
}

RatingsList.propTypes = {
  activeItem: string,
  handleActiveToggle: func
}

export default RatingsList
