import React from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCFooter from './component/BBFCFooter'
import CBBFCFooter from './component/CBBFCFooter'

const Footer = () => {
  const theme = useThemeContext()

  const componentMap = {
    bbfc: <BBFCFooter />,
    cbbfc: <CBBFCFooter />
  }

  return componentMap[theme]
}

export default Footer
