import React, { useEffect, useState } from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'
import useKeyPress from './lib/useKeyPressHook'
import { useRouter } from 'next/router'
import { useDebouncedCallback } from 'use-debounce'

import makeGraphqlRequest from '@/lib/graphql-requestor'
import createAutoCompleteQuery from '@/query/search/autocomplete'

import BBFCSearch from './component/BBFCSearch'
import CBBFCSearch from './component/CBBFCSearch'

const Search = ({ searchTerm = '' }) => {
  const theme = useThemeContext()

  const keyPress = useKeyPress()
  const router = useRouter()

  const [isOpen, setOpen] = useState(false)
  const [input, setInput] = useState(searchTerm)
  const [cursor, setCursor] = useState(-1)
  const [hovered, setHovered] = useState(undefined)
  const [results, setResults] = useState([])

  const findResults = useDebouncedCallback(async (searchTerm) => {
    if (searchTerm.length > 3) {
      const data = await makeGraphqlRequest(createAutoCompleteQuery(), {
        url: window.location.href,
        searchTerm
      })
      setResults(data.autocomplete.results)
    }
  }, 400)

  // Handles key press
  useEffect(() => {
    isOpen && keyMap[keyPress] && keyMap[keyPress]()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [keyPress, isOpen])

  // Handles Hover
  useEffect(() => {
    const cursorValue = hovered > -1 ? hovered : -1
    if (results.length) {
      setCursor(cursorValue)
    }
  }, [hovered, results.length])

  useEffect(() => {
    router.events.on('routeChangeComplete', closeAutocomplete)
    return () => {
      router.events.off('routeChangeComplete', closeAutocomplete)
    }
  })

  const closeAutocomplete = () => {
    setOpen(false)
    setCursor(-1)
    setHovered(undefined)
    setResults([])
  }

  const handleDown = () => {
    setCursor((prevState) =>
      prevState < results.length - 1 ? prevState + 1 : prevState
    )
  }

  const handleUp = () => {
    setCursor((prevState) => (prevState > -1 ? prevState - 1 : prevState))
  }

  const handleEscape = () => {
    if (isOpen) {
      closeAutocomplete()
    }
  }

  const handleSearchTermChange = (e) => {
    setInput(e.target.value)
    setOpen(true)
    findResults.callback(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (results.length && results[cursor]) {
      router.push('/release', `/release/${results[cursor].slug}`)
    } else {
      router.push('/search?q=' + input)
    }
  }

  const keyMap = {
    Up: handleUp,
    ArrowUp: handleUp,
    Down: handleDown,
    ArrowDown: handleDown,
    Esc: handleEscape,
    Escape: handleEscape
  }

  const componentMap = {
    bbfc: (
      <BBFCSearch
        searchTerm={input}
        onSearchTermChange={handleSearchTermChange}
        onSubmit={handleSubmit}
        results={results}
        setHovered={setHovered}
        activeListItemIndex={cursor}
        isOpen={isOpen}
        icon="search"
      />
    ),
    cbbfc: (
      <CBBFCSearch
        icon="balloon"
        searchTerm={input}
        onSearchTermChange={handleSearchTermChange}
        onSubmit={handleSubmit}
        results={results}
        setHovered={setHovered}
        activeListItemIndex={cursor}
        isOpen={isOpen}
      />
    )
  }

  return componentMap[theme]
}

export default Search
