import React from 'react'
import { string, func, object } from 'prop-types'
import classNames from 'classnames'

import SmartLink from '@/component/Primitive/SmartLink'
import Icon from '@/component/Primitive/Icon'
import IconButton from '@/component/Primitive/IconButton'
import Container from '@/component/Primitive/Container'
import RatingsDetail from './RatingsDetail'
import RatingsList from './RatingsList'
import AgeRatingCarousel from '@/component/Common/AgeRatingCarousel'
import Search from '@/component/Common/Search'

import Navigation from '@/component/Common/Navigation'

import styles from '../theme/BBFCHeader.module.scss'
import { displayRatings } from '../../../../../../data/ratings.json'
import { RatingContextProvider } from '@/component/Context/RatingContext'

const Header = ({
  handleActiveToggle,
  activeItem,
  user,
  instance,
  searchTerm
}) => (
  <div className={styles.HeaderWrapper}>
    <header role="banner" className={styles.Header}>
      <div className={styles.SiteTitle}>
        <button
          className={styles.CarouselWrapper}
          onClick={() => handleActiveToggle('all')}
        >
          {activeItem === 'all' ? (
            <Icon type="close" a11yText="close" />
          ) : (
            <AgeRatingCarousel />
          )}
        </button>
        <SmartLink to="/section" as="/">
          <h1>
            <div className={styles.LogoWrapper}>
              <Icon
                className={styles.Logo}
                a11yText="BBFC Logo"
                type="bbfc-logo--strapline"
              />
            </div>
            <div className={styles.TabletLogoWrapper}>
              <Icon
                className={classNames(styles.TabletLogo, styles.Logo)}
                a11yText="BBFC Logo"
                type="bbfc-logo"
              />
            </div>
          </h1>
        </SmartLink>
      </div>
      <RatingsList
        handleActiveToggle={handleActiveToggle}
        activeItem={activeItem}
      />
      <div className={styles.NavigationActions}>
        <IconButton
          className={styles.HeaderToggle}
          icon={activeItem === 'search' ? 'close' : 'search'}
          onClick={() => handleActiveToggle('search')}
          a11yText={activeItem === 'search' ? 'Hide search' : 'Reveal search'}
        />
        <IconButton
          className={styles.HeaderToggle}
          icon={activeItem === 'nav' ? 'close' : 'menu'}
          onClick={() => handleActiveToggle('nav')}
          a11yText={
            activeItem === 'nav' ? 'Hide navigation' : 'Reveal navigation'
          }
        />
      </div>
      <Navigation
        isActive={activeItem === 'nav'}
        user={user}
        instance={instance}
        onClick={() => handleActiveToggle('')}
      />
      {activeItem === 'all' && (
        <div className={styles.DetailsWrapper}>
          {displayRatings.map((rating, i) => (
            <RatingContextProvider key={i} rating={rating}>
              <RatingsDetail
                rating={rating}
                closeHandler={() => handleActiveToggle('')}
              />
            </RatingContextProvider>
          ))}
        </div>
      )}
      <Container
        center
        size="full"
        className={classNames(
          styles.SearchWrapper,
          activeItem === 'search' && styles.isActive
        )}
      >
        <Search searchTerm={searchTerm} />
      </Container>
    </header>
  </div>
)

Header.propTypes = {
  activeItem: string,
  handleActiveToggle: func,
  user: object,
  instance: object,
  searchTerm: string
}

export default Header
