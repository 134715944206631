import React from 'react'
import { string, func, bool, number, array } from 'prop-types'
import classnames from 'classnames'

import TextControl from '@/component/Primitive/TextControl'
import IconButton from '@/component/Primitive/IconButton'
import List from '@/component/Primitive/List'
import Type from '@/component/Primitive/Type'
import ListItem from './ListItem'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'

import styles from '../theme/BBFCSearch.module.scss'

const Search = ({
  icon,
  onSubmit,
  searchTerm,
  onSearchTermChange,
  results,
  setHovered,
  activeListItemIndex,
  isOpen
}) => {
  return (
    <div className={styles.Search}>
      <form onSubmit={onSubmit}>
        <div className={styles.SearchFieldWrapper}>
          <VisuallyHidden>
            <label id="search-elem" htmlFor="search">
              Search
            </label>
          </VisuallyHidden>
          <TextControl
            className={styles.SearchField}
            id="search-elem"
            name="Search"
            type="search"
            placeholder="Find films, age ratings, and more..."
            autoComplete="off"
            aria-labelledby="search-elem search-button"
            value={searchTerm}
            onChange={onSearchTermChange}
          />

          <IconButton
            className={styles.SearchButton}
            id="search-button"
            aria-haspopup="listbox"
            aria-labelledby="search-elem search-button"
            iconWidth={24}
            icon={icon}
            a11yText="Search"
            onClick={onSubmit}
            iconClassName={styles.SearchIcon}
          />
        </div>
      </form>

      <div
        className={classnames(
          styles.SearchSuggestionsWrapper,
          searchTerm.length && isOpen && results.length && styles.visible
        )}
        aria-labelledby="search-elem"
      >
        <Type size="base-large" className={styles.ListLabel}>
          Top results - press enter for full list
        </Type>
        {results.length > 0 && (
          <List
            className={styles.List}
            aria-labelledby="search-elem"
            tabIndex="-1"
            role="search"
            unstyled
          >
            {results.map((item, i) => (
              <ListItem
                key={`searchItem-${i}`}
                isActive={i === activeListItemIndex}
                role="option"
                item={item}
                onMouseEnter={() => setHovered(i)}
                onMouseLeave={() => setHovered(undefined)}
              />
            ))}
          </List>
        )}
      </div>
    </div>
  )
}

Search.propTypes = {
  searchTerm: string,
  results: array,
  onSubmit: func,
  onSearchTermChange: func,
  setHovered: func,
  activeListItemIndex: number,
  isOpen: bool,
  icon: string
}

export default Search
