import React from 'react'
import classnames from 'classnames'
import { listItemPropTypes } from '@/shape/search'
import { useThemeContext } from '@/component/Context/ThemeContext'

import SmartLink from '@/component/Primitive/SmartLink'
import Type from '@/component/Primitive/Type'
import Icon from '@/component/Primitive/Icon'

import styles from './ListItem.module.scss'
import Themes from '@/component/Primitive/Themes'

const ListItem = ({
  item,
  isActive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  role
}) => {
  const theme = useThemeContext()
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick && (() => onClick(item.value))}
      className={classnames(
        styles.Wrapper,
        isActive && styles.active,
        !item.classification && styles.bottomRadius
      )}
    >
      <SmartLink
        className={styles.ListItem}
        to="/release"
        as={`/release/${item.slug}`}
        role={role}
      >
        <Icon
          className={classnames(
            styles.RatingIcon,
            theme === 'cbbfc' && styles.iconsLarge
          )}
          type={`rating-${
            item.classification ? item.classification.toLowerCase() : ''
          }`}
          a11yText={
            item.classification
              ? `Rated ${item.classification}`
              : 'Search Suggestion'
          }
        />
        <div className={styles.ListItemTextWrapper}>
          <div className={styles.ListItemTitleWrapper}>
            <Type size="title-small" className={styles.ListItemText}>
              {`${item.title}${
                item.releaseYear ? ` (${item.releaseYear})` : ''
              }`}
            </Type>
            {item.mediaType && (
              <Type size="base-large" className={styles.ListItemType}>
                {item.mediaType}
              </Type>
            )}
          </div>
          {theme === 'cbbfc' && item.shortFormInsight && (
            <Themes
              shortFormInsight={item.shortFormInsight}
              rating={item.classification}
            />
          )}
        </div>
      </SmartLink>
    </li>
  )
}
export default ListItem

ListItem.propTypes = {
  ...listItemPropTypes
}
