import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'

import styles from './Prose.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const Prose = ({ children, className, html, color }) => {
  const isDark = useDarkContext()
  if (!children && !html) return null

  return (
    <div
      className={classNames(
        styles.Prose,
        color && styles[`color-${color}`],
        isDark && styles.inverse,
        className
      )}
      {...(html && { dangerouslySetInnerHTML: { __html: html } })}
    >
      {children}
    </div>
  )
}

Prose.propTypes = {
  children: node,
  className: string,
  html: string,
  color: string
}

export default Prose
