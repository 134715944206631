import React from 'react'
import PropTypes from 'prop-types'
import App from 'next/app'
import { NextSeo } from 'next-seo'
import withError from 'next-with-error'
import { withRouter } from 'next/router'
import Head from 'next/head'
import ErrorPage from './_error'
import * as Sentry from '@sentry/browser'
import packageJson from '../../package.json'

import MainLayout from '@/component/Layout/MainLayout'
import GlobalMeta from '@/component/Meta/Global'

import createConfig from '../site.config'

import '@/asset/scss/base.scss'
import '../public/css/mailchip.css'
import ContextProvider from '@/component/Context'

const config = createConfig()

class Page extends React.Component {
  render() {
    const { searchTerm, section, instance, user, children } = this.props
    const theme = instance ? instance.theme : 'bbfc'
    const googleSiteVerificationCode =
      instance && instance.googleSiteVerificationCode
    return (
      <ContextProvider
        gtmId={this.props.gtmId}
        isDark={
          section && section.darkModeEnabled ? section.darkModeEnabled : false
        }
        theme={theme}
      >
        <Head>
          <GlobalMeta
            theme={theme}
            {...config.meta}
            googleSiteVerificationCode={googleSiteVerificationCode}
          />
        </Head>
        <MainLayout user={user} instance={instance} searchTerm={searchTerm}>
          <NextSeo
            {...config.meta}
            titleTemplate={`%s | ${instance && instance.name}`}
          />
          {children}
        </MainLayout>
      </ContextProvider>
    )
  }
}
Page.propTypes = {
  children: PropTypes.node,
  instance: PropTypes.object,
  section: PropTypes.object,
  user: PropTypes.object,
  session: PropTypes.bool,
  searchTerm: PropTypes.string,
  gtmId: PropTypes.string
}

class MyApp extends App {
  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: process.env.SENTRY_ENV,
        release: packageJson.version,
        ignoreErrors: [
          // Next web vitals
          't is not a function'
        ]
      })
      Sentry.configureScope((scope) => {
        scope.setTag('application', 'browser')
      })
    } else {
      window.Sentry = {
        captureException: (...args) => console.error(...args)
      }
    }

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
      const cachedScrollPositions = []
      let shouldScrollRestore

      this.props.router.events.on('routeChangeStart', () => {
        cachedScrollPositions.push([window.scrollX, window.scrollY])
      })

      this.props.router.events.on('routeChangeComplete', () => {
        if (shouldScrollRestore) {
          const { x, y } = shouldScrollRestore
          window.scrollTo(x, y)
          shouldScrollRestore = false
        }
      })

      this.props.router.beforePopState((data) => {
        if (cachedScrollPositions.length) {
          const [x, y] = cachedScrollPositions.pop()
          const xValue = data.url.startsWith('/search') ? 0 : x
          const yValue = data.url.startsWith('/search') ? 0 : y
          shouldScrollRestore = { xValue, yValue }
        }
        return true
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps } = this.props

    let gtmId = ''

    if (
      this.props.pageProps &&
      this.props.pageProps.instance &&
      this.props.pageProps.instance.googleTagManagerId
    ) {
      gtmId = this.props.pageProps.instance.googleTagManagerId
    }

    if (pageProps.blank) {
      return <Component {...pageProps} />
    }

    return (
      <Page
        instance={pageProps.instance}
        section={pageProps.section}
        user={pageProps.user}
        searchTerm={pageProps.searchTerm}
        gtmId={gtmId}
      >
        <Component {...pageProps} />
      </Page>
    )
  }
}

export default withError((props) => <ErrorPage {...props} />)(withRouter(MyApp))
