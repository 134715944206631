import { string, func, bool, shape } from 'prop-types'

export const topHitPropTypes = {
  value: string,
  rating: string,
  id: string,
  url: string
}

export const suggestionPropTypes = {
  value: string,
  id: string
}

export const listItemPropTypes = {
  item: shape(topHitPropTypes), // Default
  isActive: bool,
  onClick: func,
  onMouseEnter: func,
  onMouseLeave: func,
  role: string
}
