import React from 'react'
import classNames from 'classnames'
import { bool } from 'prop-types'

import styles from './ResponsiveSpacer.module.scss'

const ResponsiveSpacer = ({ small }) => (
  <div className={classNames(styles.ResponsiveSpacer, small && styles.small)} />
)

ResponsiveSpacer.propTypes = {
  small: bool
}

export default ResponsiveSpacer
