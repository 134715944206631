import React, { useState, useEffect } from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'
import { useRouter } from 'next/router'

import BBFCHeader from './component/BBFCHeader'
import CBBFCHeader from './component/CBBFCHeader'

const Header = ({ user, instance, searchTerm }) => {
  const [activeItem, setActive] = useState('')
  const router = useRouter()

  const handleActiveToggle = (item) => {
    setActive(item === activeItem ? '' : item)
  }

  const clearActive = () => {
    setActive('')
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', clearActive)
    return () => {
      router.events.off('routeChangeComplete', clearActive)
    }
  })
  const theme = useThemeContext()

  useEffect(() =>
    activeItem !== ''
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open')
  )

  const headerProps = {
    handleActiveToggle,
    activeItem,
    user,
    instance,
    searchTerm
  }

  const componentMap = {
    bbfc: <BBFCHeader {...headerProps} />,
    cbbfc: <CBBFCHeader {...headerProps} />
  }

  return componentMap[theme]
}

export default Header
